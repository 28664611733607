import React from 'react';
import SinglePageWrapper from '../SinglePageWrapper';
const ErrorPage = () => {
    return (
        <>
        <SinglePageWrapper>
            <h2>404 &lt;/3</h2>
            <p>The site you asked for is not available. Sorry!</p>

        </SinglePageWrapper>
        </>
    );
};

export default ErrorPage;
